
import {defineComponent, onMounted} from "vue";
import KTActivityItem1 from "@/layout/header/partials/activity-timeline/Item1.vue";
import KTActivityItem3 from "@/layout/header/partials/activity-timeline/Item3.vue";
import KTActivityItem4 from "@/layout/header/partials/activity-timeline/Item4.vue";
import KTActivityItem8 from "@/layout/header/partials/activity-timeline/Item8.vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "activities",
  components: {
    KTActivityItem1,
    KTActivityItem3,
    KTActivityItem4,
    KTActivityItem8,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("activities");
    });
  },
});
